import LAYOUT from '@/layout/layout';

export default [
  {
	path: '/root-login',
	name: 'Root login',
	component: () => import(/* webpackChunkName: 'page-login' */ '@/views/Auth/RootLogin.vue'),
  },
  {
    path: '*',
    redirect: '/login',
  },
	{
		path: '/autologin',
		name: 'autologin',
		props: true,
	},
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'page-login' */ '@/views/Auth/Login.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: 'page-registration' */ '@/views/Auth/Registration.vue'),
  },
  {
    path: '/main',
    component: LAYOUT.base,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: 'page-registration' */ '@/views/Auth/Welcome.vue'),
      }
    ],
  },

];
