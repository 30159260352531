<template>
  <v-app>
    <AxiosPreloaderAndResponse />
    <ModalTransferChatUser />
    <router-view></router-view>
  </v-app>
</template>

<script>
import AxiosPreloaderAndResponse from './components/AxiosPreloaderAndResponse.vue';
import { mapGetters } from 'vuex';
import HELPERS from '@/modules/OperatorPanel/helpers';
import i18n from "@/lang/i18n";
import ModalTransferChatUser from "@/modules/OperatorPanel/views/chats/components/ModalTransferChatUser.vue";
import Vue from "vue";

export default {
  name: 'MainApp',

  sockets: {
    notification(data) {
      if(!this.user?.id) return

      this.$consoleLogHandler({ text: 'notification', data: data })

      const options = {
        position: 'top-center',
        timeout: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        closeButton: 'button',
        icon: true,
        message_alias: data.message_alias,
      };

      let title = ''
      let message = data.message;

      if (data.message_alias) {
        switch (data.message_alias) {
          case 'link_exported_file':
            title = this.$t('notification.link_exported_file');
            break;

          case 'ticket_updated':
            title =
              `${this.$t('notification.ticket_updated')} (#${data.message_data.chat_room_id})`;
            message = this.$t('modules.op.texts.view')
            break;

          case 'ticket_reassign':
            title =
              `${this.$t('notification.ticket_reassign')} (#${data.message_data.chat_room_id})`;
            message = this.$t('modules.op.texts.view')
            break;

          case 'ticket_created':
            title =
              `${this.$t('notification.ticket_created')} (#${data.message_data.chat_room_id})`;
            message = this.$t('modules.op.texts.view')
            break;

          case 'failed_store_file_mio':
            title = this.$t('notification.failed_store_file_mio');
            break;

          case 'broadcast_success':
            message = this.$t('notification.broadcast_success');
            break;

          case 'broadcast_error':
            message = this.$t('notification.broadcast_error');
            break;

          case 'email_token_set':
            message = this.$t('notification.email_token_set');
            this.reloadPageIfUserOnBotSettings()

            break;

          default: {
            const translationKey = `notification.${data.message_alias}`;
            if (this.$te(translationKey)) {
              message = this.$t(translationKey);
            } else {
              message = data.message_alias;
            }
            break;
          }
			  }
      }

      // this.$toast(message, { ...options, ...data.options });
      this.$store.dispatch('updateAjaxDialog', [
        true,
        false,
        title,
        message,
        false,
        { ...options, ...data.options },
        data?.message_type || null,
        data?.message_data || {},
      ]);
    },

    new_message(data) {
      this.messagesListener(data);
    },

    chat_room_created(data) {
      this.rulesPlayNotification(data, 'chat_room_created')
    },

    chat_room_update(data) {
      this.rulesPlayNotification(data, 'chat_room_update')
    },

    participants_connected(data){
      //notification if added me
      if(this.opUserInfo?.id === data.data?.user_id) {
        this.playNotification(
          this.$t('modules.op.texts.participant_added'),
          null,
          'participants_connected'
        );
      }
    },

    connect_error(err) {
      HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_network")]);
    },

    connect() {
      this.$socket.client.emit('login', this.opUserInfo?.id);
      this.$socket.client.emit('joinRoom', 'engine');
    },

    //chat transfers
    transfers(data) {
      this.setChatRoomTransfers(data)
    },
    send_transfer_chat(data) {
      this.sendTransferChat(data)
    },
    transfer_cancelled(data) {
      this.transferCanceled(data)
    },
    transfer_accepted(data) {
      this.transferAccepted(data)
    },
    transfer_cancelled_by_timeout(data){
      this.transferCancelledByTimeout(data)
    },

    //operator status
    status_change(data){
      this.$consoleLogHandler({text: 'STC', data: data})
      this.statusChange(data)
    },

    //copilot events
    messageFromCopilot(data) {
      this.pushMessageFromCopilot(data)
      console.log(data, 'messageFromCopilot')
    },
  },

  beforeCreate() {

    //set language for html attr lang from cookie
    let user_lang = this.$getCookie('user_lang')
    if(user_lang){
      document.documentElement.setAttribute("lang", user_lang);
    }
  },

  created() {
    this.$consoleLogHandler({
      text: 'query on created', data: this.$route?.query
    })

    if(!Vue.$cookies.get("token_c") && this.$navigating?.to?.name !== 'Login') {

      //hide navigation
      if(this.$route?.query?.hide_nav) {
        this.$store.dispatch('set_is_navigation_hidden', true);
      }
      //end hide navigation

      //save autologin_query
      if(this.$route?.name === 'autologin'){
        this.$store.dispatch('set_autologin_queries', this.$route?.query);
      }
      //save autologin_query

	  if (window?.location?.pathname !== '/root-login') {
		this.$router.push(
		  {
			  name: 'Login',
			  query:  this.$route?.query || {}
		  }
		);
	  }
    }

    if(
      Vue.$cookies.get("token_c") &&
      this.$route?.name === 'autologin' &&
      this.$route?.query?.redirect_module
    ) {
      //hide navigation
      if(this.$route?.query?.hide_nav) {
        this.$store.dispatch('set_is_navigation_hidden', true);
      }
      //end hide navigation

      //save autologin_query
      if(this.$route?.name === 'autologin'){
        this.$store.dispatch('set_autologin_queries', this.$route?.query);
      }
      //save autologin_query

      this.$router.push({ name: this.$route?.query?.redirect_module })
    }

    window.onbeforeunload = () => {
      if (this.opUserInfo && this.bot_settings?.active_id) {
        if(
          typeof this.opUserInfo?.settings_json !== 'object'
          || this.opUserInfo?.settings_json?.reset_status === true
        ) {

          // check Global setting with auto_offline
          if(this.opUserInfo?.auto_offline?.status_id !== null) {
            this.$store.dispatch('opSetUserStatus', {
              status_id: this.opUserInfo?.auto_offline?.status_id || 2,
              bot_id: this.bot_settings.active_id,
            });
          }

        }
      }
    };

    //replaced to Layout
    // this.$store.dispatch('OpAxiosGetMyInfo');

    /* for DEBUGGING State */
    // this.$store.subscribeAction((action, state) => {
    //   console.log('ACTIONNN SUBSCRIBER', action.type,)
    // })
    // this.$store.subscribe((mutation, state) => {
    //   console.log('MUTATION SUBSCRIBER', mutation)
    // });
  },

  mounted() {
    // Audio instance
    const audio = new Audio('/500-ms-silence.mp3');
    let $this = this

    // Adding an event listener to the entire document
    document.body.addEventListener('click', function playAudioOnce() {
      audio.play()
        .then(() => {
          $this.$consoleLogHandler('Audio started playing successfully! on MOUNTED')
          $this.$store.dispatch('OpSetNotificationsEnabled', true)
          // Remove event listener after the audio has started
          document.body.removeEventListener('click', playAudioOnce);
        })
        .catch(err => {
          this.$consoleLogHandler({ text: 'Audio started playing Error! on MOUNTED', data: err })
          $this.$store.dispatch('OpSetNotificationsEnabled', false)
        });
    });


    this.$root.$on('initCodeWidget', () => {
      this.initCodeChatWidget()
    });

    this.$root.$on('initScriptWidget', () => {
      this.initScriptWidget()
    });
  },

  computed: {
    ...mapGetters([
      'opChatRooms',
      'opActiveChatRoom',
      'opActiveChatRoomFromState',
      'opUserInfo',
      'bot_settings',
      'opSubjectList',
      'user',
      'get_instance_settings',
    ]),
  },

  watch: {
    //TODO: strange code
    opUserInfo: function (user) {
      if (user?.id) {
        this.$socket.client.emit('login', user.id);
        this.$socket.client.emit('joinRoom', 'engine');
      }
    },

    /* for DEBUGGING Route */
    // '$route' (to, from) {
    //   console.log('Route changed from ' + from.path + ' to ' + to.path);
    // },
  },

  methods: {
    async messagesListener(event) {
      const message = event.data;
      const roomId = (typeof event.data.chat_room_id === 'string')
        ? event.data.chat_room_id = Number(event.data.chat_room_id)
        : event.data.chat_room_id


      //message from Copilot
      //TODO: here for now

      if (message?.system || message?.isUser) {

        //save msg in Active Dialog if isUser
        if (this.opActiveChatRoom?.id === roomId) {
          await this.$store.dispatch('opAddMessageToActiveChatRooms', event);
        } else {
          await this.$store.dispatch('opAddMessageToChatRoom', event);
        }
        //save msg in Active Dialog if isUser

        return;
      }

      const authorId = message?.participant_id;
      const text = message?.message_text;

      const isEqualAuthorId = (participant) => participant.id === authorId;

      const isExistInChatRooms = (room) => room.id === roomId;

      const getChatRoom = (room) => {
        const participants = room.participants || [];
        return participants.some(isEqualAuthorId);
      };

      const getParticipant = () => {
        const chatRoom = this.opChatRooms.find(getChatRoom);

        if (chatRoom === undefined) {
          return null;
        }

        const participant = chatRoom.participants.find(isEqualAuthorId);

        return participant;
      };

      const authorObj = getParticipant();

      //return if room not exist in Rooms
      if (!this.opChatRooms.find(isExistInChatRooms)) return

      if (authorObj?.user_id === this.opUserInfo.id) {
        return;
      }

      const author = authorObj?.client || authorObj?.user;

      const authorName = HELPERS.formatClientName(author);

      //check if user exist in participants of the chat
      if(this.opChatRooms.find(isExistInChatRooms)?.participants?.length){
        const isMeInChat = this.opChatRooms.find(isExistInChatRooms)?.participants
          .some(({user_id}) => user_id && user_id === this.opUserInfo.id)

        if (isMeInChat) {
          await this.playNotification(
            authorName,
            text,
            'new_message',
            message,
            message?.id
          );
        }
      }

      const urlParams = new URLSearchParams(window.location.search);

      if (
        (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id'))
        || (+urlParams.get('chat_room_id') !== roomId)
      ) {
        this.$store.dispatch('opSetChatNotification', event);
      } else if (this.opActiveChatRoom?.id === roomId) {
        this.$store.dispatch('opAddMessageToActiveChatRooms', event);
      }

      // if (
      //   (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id')) ||
      //   (urlParams.get('chat_room_id') &&
      //     this.opActiveChatRoom?.id !== +urlParams.get('chat_room_id'))
      // ) {
      //   this.$store.dispatch('opSetChatNotification', event);
      // }
    },

    //chat transfers
    setChatRoomTransfers(data) {
      const transfers = data?.data?.transfers

      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', transfers)

      if(Object.keys(transfers).length) {
        for(let i in transfers) {
          if(Number(i) === this.opUserInfo.id)
            this.sendTransferChat({
              data: transfers[i],
              transfers: transfers,
              withoutTimer: true,
            })
        }
      }
    },

    async sendTransferChat(data) {
      await this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_receive === this.opUserInfo.id) {
        await this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: true,
          data: data.data,
          withoutTimer: data?.withoutTimer || null
        })

        await this.playNotification(this.$t('modules.op.transfer_modal.title'), data?.system_message, 'send_transfer_chat')
      }
    },

    transferCanceled(data) {
      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_sender === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })
        // this.$store.dispatch('opSetTransferDisabled', false);
        // this.$toast(this.$t('modules.op.transfer_modal.operator_cancel_transfer'));
        this.$store.dispatch('updateAjaxDialog', [
          true,
          false,
          '',
          this.$t('modules.op.transfer_modal.operator_cancel_transfer'),
        ]);
      }

      if(data.data.user_id_receive === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })

        // this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
        this.$store.dispatch('updateAjaxDialog', [
          true,
          false,
          '',
          this.$t('modules.op.transfer_modal.transfer_is_canceled'),
        ]);
      }
    },
    transferAccepted(data) {
      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_sender === this.opUserInfo.id) {
        // this.$store.dispatch('opSetTransferDisabled', false);
        // this.$toast(this.$t('modules.op.transfer_modal.operator_accept_dialog'));
        this.$store.dispatch('updateAjaxDialog', [
          true,
          false,
          '',
          this.$t('modules.op.transfer_modal.operator_accept_dialog'),
          false,
          {},
          'link',
          {chat_room_id: data.data.chat_room_id},
        ]);

        //exit from dialog if it activated
        if (this.opActiveChatRoomFromState === data.data.chat_room_id) {
          
          this.$store.dispatch('opClearActiveChat');

          this.$store.dispatch('updateAjaxDialog', [
            true,
            false,
            '',
            this.$t('modules.op.texts.chat_exited'),
          ]);
        }
      }
    },

    transferCancelledByTimeout(data) {
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_receive === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })
        this.$nextTick(() => {
          // this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
          this.$store.dispatch('updateAjaxDialog', [
            true,
            false,
            '',
            this.$t('modules.op.transfer_modal.transfer_is_canceled'),
          ]);
        })
      }

      if(data.data.user_id_sender === this.opUserInfo.id) {
        // this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
        this.$store.dispatch('updateAjaxDialog', [
          true,
          false,
          '',
          this.$t('modules.op.transfer_modal.transfer_is_canceled'),
        ]);
      }
    },

    async rulesPlayNotification(data, eventName = null) {
      const chatRoom = data?.data

      //Check if dialog not available for me
      if (chatRoom?.subject_id) {
        const even = ({id}) => id === chatRoom.subject_id;
        if ( !this.opSubjectList?.some(even) ) return
      }
      //end Check if dialog not available for me

      await this.$store.dispatch('opSetChatNotification', 'update');

      if (chatRoom?.status === 'new' && !chatRoom?.from_operator) {
        await this.playNotification(
          this.$t('modules.op.texts.new_dialog'),
          null,
          eventName,
          null,
          chatRoom?.id
        )
        if(this.opUserInfo?.active_status?.can_receive) {
          // this.$toast(this.$t('modules.op.texts.new_dialog'))
          await this.$store.dispatch('updateAjaxDialog', [
            true,
            false,
            '',
            this.$t('modules.op.texts.new_dialog'),
            false,
            {},
            'link',
            {chat_room_id: chatRoom.id},
          ]);
        }
      } else if(
        chatRoom?.status === 'processed' &&
        chatRoom?.extra_data?.auto_connected === this.opUserInfo?.id
      ) {
        await this.playNotification(
          null,
          null,
          eventName,
          null,
          chatRoom?.id
        );
      }
    },

    async playNotification(
      title = null,
      text = null,
      eventName = null,
      data = null,
      tag_id = null,
    ) {
      //notifications only for online status
      if(!this.opUserInfo?.active_status?.can_receive) return

      //Second check level from data
      //check from message_data
      if(data?.message_data) {
        try{
          const message_data = JSON.parse(data?.message_data)

          //check if message from Copilot
          if(message_data?.from === 'copilot') return

        } catch(e) {
          this.$consoleLogHandler({
            text:'Error on parse message FROM is Copilot:',
            data: e }
          )
        }
      }
      //end Second check level from data

      const settings_json =  this.opUserInfo?.settings_json

      //play Notification if NOT Disabled settings
      if (!settings_json?.notifications_browser_disabled || settings_json?.notifications_browser_disabled !== true ) {
        try {
          await HELPERS.showBrowserNotification({
            title: title || this.$t('modules.op.texts.new_message'),
            body: text || '',
            tag: tag_id || null,
          });
        } catch(e) {
          //
        }
      }

      //play Sound if Enabled settings
      if (settings_json?.notifications_sound_enabled) {
        try {
          const audioSound = new Audio(this._returnAudioSrcByUserSettings(eventName, settings_json));
          await audioSound.load()
          await audioSound.play()
            .then(() => this.$consoleLogHandler('Audio started playing successfully!'))
            .catch(err => this.$consoleLogHandler({text:'Error playing audio:', data: err }));
        } catch(e) {
          console.log('catch Error on new Audio', e)
        }
      }
    }, 

    _returnAudioSrcByUserSettings(eventName = null, settings_json) {
      let soundUrl = '/sound_5.mp3'
      if (settings_json?.selectedSound && eventName) {
        soundUrl = settings_json.selectedSound?.[eventName]?.sound || soundUrl
      } 
      
      return soundUrl;
    },

    reloadPageIfUserOnBotSettings() {
      if (this.$route?.name === 'bot_settings') {
        setTimeout(() => {
          location.reload();
        }, 5000)
      }
    },

    async statusChange(data) {
      if(data?.user_id === this.user?.user?.id) {
        await this.$store.dispatch('setUserInfoKey', {
          key: 'status_id',
          value: data.status_id,
        });
      }
    },
    //copilot
    async pushMessageFromCopilot(event) {
      console.log(event, 'pushMessageFromCopilot')

      if (this.opActiveChatRoom?.id === event.data.chat_room_id) {
        await this.$store.dispatch('opAddMessageToActiveChatRooms', event);
      }
    },


    //INIT KW WIDGET
    async initScriptWidget() {
      // let widget_init_params = this.get_instance_settings.find((s) => s.key === 'widget_init_params')?.value
      let widget_url = this.get_instance_settings.find((s) => s.key === 'operator_widget_url')?.value
      widget_url = `${widget_url}kwjs.js`

      // enable Widget 2.0
      const operator_widget_2_0_enabled = this.get_instance_settings.find((s) => s.key === 'operator_widget_2_0_enabled')?.value
      if(operator_widget_2_0_enabled) {
        widget_url = 'https://widget.kwizbot.io/kwjs_v2.js'
      }
      // end get widget js url

      await (function (k, w, i, z, b, o, t) {
        k["KwizbotWidget"] = b;
        k[b] =
          k[b] ||
          function () {
            w.head.insertAdjacentHTML(
              "beforeend",
              '<style type="text/css"></style>'
            );
            (k[b].q = k[b].q || []).push(arguments);
          };
        k[b].l = 1 * new Date();
        o = w.createElement(i);
        o.id = 'kwizbotScript'; // Add an id to the script element
        t = w.getElementsByTagName(i)[0];
        o.async = 1;
        o.src = z;
        t.parentNode.insertBefore(o, t);
      })(
        window,
        document,
        "script",
        widget_url,
        "kw"
      );
    },

    async initCodeChatWidget() {
      const bot_id = this.get_instance_settings.find((s) => s.key === 'operator_widget_bot')?.value

      let kw_init_params = {
        language_code: "uk",
        bot_id: bot_id || '1',
        launcher: {
          type: "image",
          staticImg: {
            src: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
            fit: "unset",
          },
        },
        titleImageUrl: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
        botAvatarImageUrl: 'https://storage.static.kwizbot.io/kwdev.kwizbot.io/static/CopilotLogo.png',
        title: "AI Helper",
        subtitle:"AI помічник оператора",
        btn_restart_enabled: true,
        params_from_site: {},
        launcherIsHiddenState: true,
        widgetButton: {
          type: 'button',
          position: {
            y: 'bottom',
            x: 'right',
            offset_y: 40,
            offset_x: 70,
          }
        }
      }

      // get widget js url
      // let widget_url = this.get_instance_settings.find((s) => s.key === 'operator_widget_url')?.value
      // widget_url = `${widget_url}kwjs.js`
      //
      // // enable Widget 2.0
      const operator_widget_2_0_enabled = this.get_instance_settings.find((s) => s.key === 'operator_widget_2_0_enabled')?.value
      if(operator_widget_2_0_enabled) {
      //   widget_url = 'https://widget.kwizbot.io/kwjs_v2.js'
        kw_init_params['v2_value'] = operator_widget_2_0_enabled
      }
      // end get widget js url

      // set additional params from settings
      let widget_init_params = this.get_instance_settings.find((s) => s.key === 'widget_init_params')?.value
      if(widget_init_params) {
        try{
          widget_init_params = JSON.parse(widget_init_params)
          kw_init_params = {...kw_init_params, ...widget_init_params }
        } catch(e) {
          console.log(e, 'error parse widget_init_params')
        }
      }

      //macro_sender_user
      kw_init_params['params_from_site'] = {
        ...kw_init_params['params_from_site'],
        macro_sender_user: this.opUserInfo
      }

      // end set additional params from settings

      // await (function (k, w, i, z, b, o, t) {
      //   k["KwizbotWidget"] = b;
      //   (k[b] =
      //     k[b] ||
      //     function () {
      //       w.head.insertAdjacentHTML(
      //         "beforeend",
      //         '<style type="text/css"></style>'
      //       );
      //       (k[b].q = k[b].q || []).push(arguments);
      //     }),
      //     (k[b].l = 1 * new Date());
      //   (o = w.createElement(i)), (t = w.getElementsByTagName(i)[0]);
      //   o.async = 1;
      //   o.src = z;
      //   t.parentNode.insertBefore(o, t);
      // })(
      //   window,
      //   document,
      //   "script",
      //   `${widget_url}kwjs.js`,
      //   "kw"
      // );

      try {
        // eslint-disable-next-line
        await kw("init", kw_init_params);
      } catch (e) {
        console.log(e)
      }

    },
  },

  components: {
    AxiosPreloaderAndResponse,
    ModalTransferChatUser,
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-application--wrap {
  z-index: 1;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    min-height: fit-content;
  }
}
</style>
